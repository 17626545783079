<template>
  <main class="column-span-3 grid-wrapper" style="padding-bottom: 50px">
    <div class="column-span-3 grid-wrapper">
      <p class="-xstb column-span-1">Project Framework</p>
      <div class="column-span-2 flex align-center justify-flex-start gap8">
        <img
          src="../../../assets/thumbnails/riba-icon.png"
          alt=""
          class="framework-image"
        />
        <p class="-xstr">
          {{ selectedTemplate.name }}
        </p>

        <!-- <p v-for="template in templates" :key="template">
          {{ template }}
        </p> -->
      </div>
    </div>
    <hr class="column-span-3 hr-form" />

    <div class="column-span-3 grid-wrapper">
      <p class="-xstb column-span-1">Project Title</p>
      <input
        type="text"
        v-model.trim="projectName"
        class="-xstr column-span-2"
      />
    </div>
    <div class="column-span-3 grid-wrapper">
      <p class="-xstb column-span-1">Project Reference</p>
      <input
        type="text"
        v-model.trim="referenceName"
        class="-xstr column-span-2"
      />
    </div>
    <hr class="column-span-3 hr-form" />
    <div class="column-span-3 grid-wrapper">
      <div class="column-span-1 flex-column">
        <p class="-xstb">Project Image</p>
        <p class="-xstr">This will be displayed on project.</p>
      </div>
      <div class="column-span-2 flex justify-space-between gap8">
        <img
          src="../../../assets/thumbnails/render.jpg"
          alt=""
          class="project-image"
        />
        <div class="flex justify-center align-start gap16 photo-controls">
          <p class="-xstb">Delete</p>
          <p class="-xstb">Update</p>
        </div>
      </div>
    </div>
    <hr class="column-span-3 hr-form" />
    <div class="column-span-3 grid-wrapper">
      <div class="column-span-1 flex-column justify-start">
        <p class="-xstb">Project Description</p>
        <p class="-xstr">Write a short introduction.</p>
      </div>
      <div class="column-span-2 flex-column width100 align-start gap4">
        <textarea
          maxlength="255"
          placeholder="Start typing here..."
          v-model.trim="bio"
          class="-xstr column-span-2"
        ></textarea>
        <p class="-xstr" style="color: var(--gray3)">
          {{ $getRemainingChars(bio, 255) }} characters left
        </p>
      </div>
    </div>
    <div class="column-span-3 grid-wrapper">
      <p class="-xstb column-span-1">Country</p>
      <div class="column-span-2 flex align-center justify-space-between gap8">
        <img
          :src="selectedCountry.flag"
          :alt="selectedCountry.name"
          class="flag"
        />
        <select @change="setSelectedCountry()" ref="countryRef" class="-xstr">
          <option selected disabled hidden>{{ selectedCountry.name }}</option>
          <option v-for="country in countriesList" :key="country">
            {{ country.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="column-span-3 grid-wrapper">
      <p class="-xstb column-span-1">Project Location</p>
      <input
        type="text"
        v-model.trim="projectLocation"
        class="-xstr column-span-2"
      />
    </div>
    <hr class="column-span-3 hr-form" />
    <div class="column-span-3 grid-wrapper">
      <div class="column-span-1 flex-column">
        <p class="-xstb">Time Zone</p>
        <p class="-xstr">Define the time zone to project.</p>
      </div>
      <select class="-xstr column-span-2">
        <option selected disabled hidden>{{ selectedTimezone.name }}</option>
        <option v-for="timezone in timezones" :key="timezone">
          {{ timezone }}
        </option>
      </select>
    </div>

    <hr class="column-span-3 hr-form" />
    <section class="column-span-3">
      <BaseCancelSave />
    </section>
  </main>
</template>

<script>
import DynaBadge from "../../../components/utils/DynaBadge.vue";
import BaseCancelSave from "../../../components/utils/BaseCancelSave.vue";
import { getCountries } from "../../../services/requests/get/getCountries";
export default {
  components: {
    DynaBadge,
    BaseCancelSave,
  },
  data() {
    return {
      projectName: "Building Ribeiro",
      referenceName: "NB-001",
      projectLocation: "Porto - Portugal",
      bio: "",
      jobTitle: "Project Coordinator",
      selectedCountry: {
        name: "United Kingdom",
        flag: "https://flagcdn.com/gb.svg",
      },
      countriesList: null,
      templates: ["RIBA", "EX1", "EX2"],
      selectedTemplate: {
        name: "RIBA POW",
      },
      timezones: [
        "GMT -12",
        "GMT -11",
        "GMT -10",
        "GMT -9:30",
        "GMT -9",
        "GMT -8",
        "GMT -7",
        "GMT -6",
        "GMT -5",
        "GMT -4",
        "GMT -3",
        "GMT -2",
        "GMT -1",
        "GMT 0",
        "GMT +1",
        "GMT +2",
        "GMT +3",
        "GMT +3:30",
        "GMT +4",
        "GMT +4:30",
        "GMT +5",
        "GMT +5:30",
        "GMT +5:45",
        "GMT +6",
        "GMT +6:30",
        "GMT +7",
        "GMT +8",
        "GMT +8:45",
        "GMT +9",
        "GMT +9:30",
        "GMT +10",
        "GMT +10:30",
        "GMT +11",
        "GMT +11:30",
        "GMT +12",
        "GMT +12:45",
        "GMT +13",
        "GMT +14",
      ],
      selectedTimezone: {
        name: "GMT 0",
      },
      //REFS
      countryRef: "countryRef",
    };
  },
  async created() {
    this.countriesList = await getCountries();
  },
  methods: {
    setSelectedCountry() {
      const domElement = this.$refs.countryRef.value.trim();
      console.log(domElement);
      this.selectedCountry = this.countriesList.find(
        (country) => country.name === domElement
      );
    },
  },
};
</script>

<style scoped>
.grid-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
}
.column-span-3 {
  grid-column: span 3;
  width: 100%;
  justify-items: start;
}
.column-span-2 {
  grid-column: span 2;
  width: 100%;
  justify-items: start;
}
.column-span-1 {
  grid-column: span 1;
  width: 100%;
  text-align: start;
}

.project-image {
  width: 150px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
}
.framework-image {
  width: 50px;
  height: 50px;
}
.photo-controls p:first-child {
  cursor: pointer;
  color: var(--gray2);
}
.photo-controls p:last-child {
  cursor: pointer;
  color: var(--secondary);
}
.photo-controls p:first-child:hover {
  color: var(--error);
}
.photo-controls p:last-child:hover {
  color: var(--info);
}

.flag {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
  outline: 1px solid var(--gray5);
}

input,
select,
option,
textarea {
  border: 1px solid var(--gray5);
  border-radius: 4px;
  padding: 8px;
  outline: none;
}
input,
select {
  height: 36px;
}
input,
select,
textarea {
  width: 100%;
}

textarea {
  resize: none;
  height: 110px;
}
input[type="text"]:focus,
select:focus,
option:focus,
textarea:focus {
  border-color: var(--focus1);
  box-shadow: 0 0 1px 3px var(--focus2);
}
.btn {
  cursor: pointer;
  border-radius: 4px;
  padding-block: 8px;
  padding-inline: 16px;
}
.btn:first-child {
  border: 1px solid var(--gray5);
}
.btn:last-child {
  background: var(--secondary);
  color: var(--white1);
  transition: 500ms ease;
}
.btn:first-child:hover {
  background: var(--gray6);
}
.btn:last-child:hover {
  box-shadow: inset 0 -50px 0 0 var(--success);
}

@media screen and (max-width: 1022px) {
  .column-span-2 {
    grid-column: span 3;
  }
  .column-span-1 {
    grid-column: span 3;
  }
}
@media screen and (max-width: 719px) {
  .user-photo-wrapper {
    justify-content: flex-start;
    gap: 40px;
  }
}
</style>
