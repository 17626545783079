<template>
  <main class="column-span-3 grid-wrapper" style="padding-bottom: 50px">
    <div class="column-span-3 grid-wrapper">
      <p class="-xstb column-span-1">Unit Template</p>
      <div class="column-span-2 flex align-end justify-flex-start gap8">
        <div class="template-image" :class="`i-${templateToLowercase}`"></div>
        <p class="-xstb" style="padding-bottom: 4px">
          {{ template }}
        </p>
      </div>
    </div>
    <hr class="column-span-3 hr-form" />
    <div class="column-span-3 grid-wrapper">
      <p class="-xstb column-span-1">Unit Title</p>
      <input type="text" v-model.trim="unitName" class="-xstr column-span-2" />
    </div>
    <div class="column-span-3 grid-wrapper">
      <p class="-xstb column-span-1">Unit Reference</p>
      <input
        type="text"
        v-model.trim="referenceName"
        class="-xstr column-span-2"
      />
    </div>
    <hr class="column-span-3 hr-form" />
    <div class="column-span-3 grid-wrapper">
      <div class="column-span-1 flex-column">
        <p class="-xstb">Unit Image</p>
        <p class="-xstr">This will be displayed on project.</p>
      </div>
      <div class="column-span-2 flex justify-space-between gap8">
        <img
          src="../../../assets/thumbnails/render.jpg"
          alt=""
          class="project-image"
        />
        <div class="flex justify-center align-start gap16 photo-controls">
          <p class="-xstb">Delete</p>
          <p class="-xstb">Update</p>
        </div>
      </div>
    </div>
    <hr class="column-span-3 hr-form" />
    <div class="column-span-3 grid-wrapper">
      <div class="column-span-1 flex-column justify-start">
        <p class="-xstb">Unit Description</p>
        <p class="-xstr">Write a short introduction.</p>
      </div>
      <div class="column-span-2 flex-column width100 align-start gap4">
        <textarea
          maxlength="255"
          placeholder="Start typing here..."
          v-model.trim="bio"
          class="-xstr column-span-2"
        ></textarea>
        <p class="-xstr" style="color: var(--gray3)">
          {{ $getRemainingChars(bio, 255) }} characters left
        </p>
      </div>
    </div>

    <hr class="column-span-3 hr-form" />
    <section class="column-span-3">
      <BaseCancelSave />
    </section>
  </main>
</template>

<script>
import DynaBadge from "../../../components/utils/DynaBadge.vue";
import BaseCancelSave from "../../../components/utils/BaseCancelSave.vue";
import { getCountries } from "../../../services/requests/get/getCountries";
export default {
  components: {
    DynaBadge,
    BaseCancelSave,
  },
  data() {
    return {
      unitName: "Building Ribeiro",
      referenceName: "NB-001",
      bio: "",
      template: "Building",
    };
  },
  computed: {
    templateToLowercase() {
      console.log(this.template.toLowerCase());
      return this.template.toLowerCase();
    },
  },
};
</script>

<style scoped>
.grid-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
}
.column-span-3 {
  grid-column: span 3;
  width: 100%;
  justify-items: start;
}
.column-span-2 {
  grid-column: span 2;
  width: 100%;
  justify-items: start;
}
.column-span-1 {
  grid-column: span 1;
  width: 100%;
  text-align: start;
}

.project-image {
  width: 150px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
}
.template-image {
  width: 50px;
  height: 50px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.photo-controls p:first-child {
  cursor: pointer;
  color: var(--gray2);
}
.photo-controls p:last-child {
  cursor: pointer;
  color: var(--secondary);
}
.photo-controls p:first-child:hover {
  color: var(--error);
}
.photo-controls p:last-child:hover {
  color: var(--info);
}

input,
select,
option,
textarea {
  border: 1px solid var(--gray5);
  border-radius: 4px;
  padding: 8px;
  outline: none;
}
input,
select {
  height: 36px;
}
input,
select,
textarea {
  width: 100%;
}

textarea {
  resize: none;
  height: 110px;
}
input[type="text"]:focus,
select:focus,
option:focus,
textarea:focus {
  border-color: var(--focus1);
  box-shadow: 0 0 1px 3px var(--focus2);
}

@media screen and (max-width: 1022px) {
  .column-span-2 {
    grid-column: span 3;
  }
  .column-span-1 {
    grid-column: span 3;
  }
}
@media screen and (max-width: 719px) {
  .user-photo-wrapper {
    justify-content: flex-start;
    gap: 40px;
  }
}
</style>
